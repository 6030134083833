/** @jsx jsx */
import { jsx } from "theme-ui"
import { Box } from "@theme-ui/components"
import ButtonAccent from "./button-accent"
import Title from "./title"
import PostType from "./tag-post-type"

const HeadlineTopic = () => {
  return (
    <div>
      {/* Tags / type */}
      <PostType text="Rant – opinion" explicit={false} />
      
      {/* Hero title */}
      <Title text="The pandemic of “uncertain”" />

      {/* Hero perex */}
      <Box sx={{ 
        fontFamily: `Open Sans`,
        fontWeight: `400`,
        fontSize: `1rem`,
        lineHeight: `2rem`,
        color: `#fff`,
        padding: `0 60px 0 60px`,
        width: `50vw`
       }}>
        It’s 4 am, my bus is docking at the main station. After a week-long meditative/mindful/party bender I’m finally back in Prague. Everything is quiet while I’m walking home. I’m setting my alarm clock for 9:30 to make it to breakfast with my boss just in time. I only have a hunch what he wants to talk about. And I know for sure I want to tell him I want to quit.
      </Box>

      {/* Hero read more */}
      <Box sx={{ padding: `60px`, width: `50vw` }}>
         <ButtonAccent text="Read more" targetUrl="/pandemic-of-uncertain" />
      </Box>
    </div>
  )
}

export default HeadlineTopic