/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Flex } from "@theme-ui/components"

const MouseScrollIndicator = () => {
  return (
    <React.Fragment>
      <Flex sx={{ display: `block`, alignItems: `center`, height: `130px`, margin: `0 auto` }}>
        <svg width="40" height="70" viewBox="0 0 50 120">
          <rect id="scroll" x="0" y="5" rx="25" ry="25" width="50" height="80" stroke="#2A2D34" fill="transparent" strokeWidth="6"></rect>
          <circle id="circle--shape" cx="25" cy="32" r="8" fill="#2A2D34"></circle>
        </svg>
      </Flex>
    </React.Fragment>
  )
}

export default MouseScrollIndicator