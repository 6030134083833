/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import { Box } from "@theme-ui/components"
import ExplicitContent from "./tag-explicit"

type PostTypeProps = {
  text: string
  explicit: boolean
}

const PostType = ({ text, explicit }: PostTypeProps) => (
  <Box sx={{ padding: `120px 60px 0 60px`, width: `80vw` }}>
    <Box
      sx={{ fontWeight: `800`, fontSize: `0.8rem`, fontFamily: `Montserrat`, lineHeight: `1rem`, color: `#2A2D34`, letterSpacing: `2.4px`, textTransform: `Uppercase`, }}
    >
      {explicit === true && 
        <ExplicitContent />
      }
      {text}
    </Box>
  </Box>
)

export default PostType
