/** @jsx jsx */
import { jsx } from "theme-ui"

const ExplicitContent = () => (
    <span
      sx={{
        fontWeight: `800`, fontSize: `0.8rem`, fontFamily: `Montserrat`, lineHeight: `1rem`, color: `#BB0000`, letterSpacing: `1px`, textTransform: `Uppercase`,
        border: `2px solid #bb0000`, paddingLeft: `2px`, paddingRight: `1px`, marginRight: 2
      }}
    >
      Explicit
    </span>
)

export default ExplicitContent
