/** @jsx jsx */
import { jsx } from "theme-ui"
import Layout from "./layout"
import Listing from "./listing"
import useMinimalBlogConfig from "../hooks/use-minimal-blog-config"
import replaceSlashes from "../utils/replaceSlashes"
import ButtonDark from "./button-dark"
import Header from "./header"
import { Flex, Box } from "@theme-ui/components"

type PostsProps = {
  posts: {
    slug: string
    title: string
    date: string
    banner: string
    excerpt: string
    description: string
    timeToRead?: number
    tags?: {
      name: string
      slug: string
    }[]
  }[]
}

const Homepage = ({ posts }: PostsProps) => {
  const { basePath, blogPath } = useMinimalBlogConfig()

  return (
    <Box>
    <Header />
    <Layout>
      <Listing posts={posts} showTags={false} />
      <Flex sx={{ display: `block`, textAlign: `center`, margin: `0 auto`, mb: 5}}>
        <ButtonDark text="See all posts" targetUrl={replaceSlashes(`/${basePath}/${blogPath}`)} />
      </Flex>
    </Layout>
    </Box>
  )
}

export default Homepage
